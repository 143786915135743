import React, { useState, useCallback, useEffect } from 'react';
import '../../styles.css';
import { intl } from 'lib/intl';
import { NumericFormat } from 'react-number-format';
import { bidsApi } from 'api';
import { useAuctionContext } from '../../';
import { BatchTitle } from '../batch-title';
import { ImagesSliderAuction } from 'components';
import { Link } from 'react-router-dom';
import { saveLastBatch } from 'utils/handleSession';
import { convertToNumber } from 'utils/converters';
import { Notification } from 'components/common/Notification';
import { useParams } from 'react-router-dom';

export function AuctionDetailsHeader() {
  const { batch, userToken } = useAuctionContext();
  const params = useParams();

  const [value, setValue] = useState();

  const [notificationMessage, setNotificationMessage] = useState({
    message: '',
    type: '',
  });

  function resetNotificationMessage() {
    setNotificationMessage({
      message: '',
      type: '',
    });
  }

  const handleBidSubmit = async (event) => {
    event.preventDefault();

    try {
      const form = new FormData(event.currentTarget);

      const bidValue = form.get('bidValue');
      const value = convertToNumber(bidValue);

      await bidsApi.create({
        batchId: batch.id,
        value,
      });
      setNotificationMessage({
        message: 'Oferta enviada com sucesso!',
        type: 'success',
      });
    } catch (err) {
      setNotificationMessage({
        message: err?.response?.data?.error,
        type: 'error',
      });
    }
  };

  const getData = useCallback(async (batchId) => {
    try {
      const response = await bidsApi.showLastBid({ batchId });

      setValue(response?.value);
    } catch (err) {
    } finally {
    }
  }, []);

  useEffect(() => {
    const { id } = params;
    getData(id);
  }, [getData, params]);

  return (
    <>
      <Notification
        message={intl(notificationMessage?.message)}
        isOpen={!!notificationMessage?.message}
        type={notificationMessage?.type}
        onClose={resetNotificationMessage}
      />
      <div className="w-100 mb-4 header-container">
        <ImagesSliderAuction photos={batch?.photos} />
        <div className="col-xl-6 col-lg-5">
          <div
            className="product-details-right wow fadeInDown headerAuctionDetails"
            data-wow-duration="1.5s"
            data-wow-delay=".2s"
          >
            <BatchTitle batchId={batch?.id} />
            <div className="bid-form m-0">
              <div className="form-title">
                <h5>{intl('bid_now')}</h5>
                {/* <p>
                {intl('minimum_bid')}
                {convertToMonetaryValue(batch?.minimumValue)}
              </p> */}
              </div>
              <form onSubmit={handleBidSubmit}>
                <div className="form-inner gap-2 ">
                  <NumericFormat
                    name="bidValue"
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="R$"
                    placeholder="R$0"
                    disabled={!userToken}
                    decimalScale={2}
                    fixedDecimalScale
                    allowNegative={false}
                    customInput="input"
                    value={value !== undefined ? value : ''}
                    onValueChange={(values) => {
                      const { floatValue } = values;
                      setValue(floatValue);
                    }}
                  />
                  <button
                    disabled={!userToken}
                    type="submit"
                    className="eg-btn btn--primary btn--sm"
                  >
                    {intl('place_bid')}
                  </button>
                </div>
              </form>
              {!userToken ? (
                <p style={{ marginTop: 4 }}>
                  <p>
                    <span>OLA!</span>
                    {intl('needLoginInit')}{' '}
                    <Link
                      style={{
                        color: '#3b5534',
                        borderBottom: '1px solid #3b5534',
                      }}
                      to={`${process.env.PUBLIC_URL}/buyer-registration`}
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        saveLastBatch(window.location.pathname.substring(1));
                      }}
                    >
                      {intl('needLogin')}
                    </Link>{' '}
                    {intl('needLoginEnd')}
                  </p>
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
