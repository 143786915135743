import React from 'react';
import ReactDOM from 'react-dom';
import { IntlWrapper } from 'lib/intl';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Layout from './App';
import MainLayout from 'components/layout/MainLayout';
import { Faq } from 'pages/faq';
import { Blog } from 'pages/blog';
import { Login } from 'pages/login';
import { About } from 'pages/about';
import { SignUp } from 'pages/signUp';
import { Contact } from 'pages/contact';
import { Merchant } from 'pages/merchant';
import { Dashboard } from 'pages/dashboard';
import { ErrorPage } from 'pages/error-page';
import { EventPage } from 'pages/event-page';
import { HowItWork } from 'pages/how-it-work';
import { LiveAuction } from 'pages/live-auction';
import { BlogDetails } from 'pages/blog-details';
import { AuctionDetails } from 'pages/auction-details';
import { ProducerDetails } from 'pages/producer-details';
import { BuyerRegistration } from 'pages/buyer-registration';
import { MyAccount } from 'pages/my-account';

import './index.css';

// console.log = console.warn = console.error = () => {};

function Root() {
  return (
    <IntlWrapper>
      <BrowserRouter basename="/">
        <Switch>
          <Route exact path="/" component={MainLayout} />
          <Layout>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/about`}
              component={About}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact`}
              component={Contact}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/buyer-registration`}
              component={BuyerRegistration}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/event-page`}
              component={EventPage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/error`}
              component={ErrorPage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/signup`}
              component={SignUp}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/login`}
              component={Login}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/auction-details/:id`}
              component={AuctionDetails}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/dashboard`}
              component={Dashboard}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog`}
              component={Blog}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog-details`}
              component={BlogDetails}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/producer-details/:id`}
              component={ProducerDetails}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/live-auction`}
              component={LiveAuction}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/how-works`}
              component={HowItWork}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/faq`}
              component={Faq}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/join-merchant`}
              component={Merchant}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/my-account`}
              component={MyAccount}
            />
          </Layout>
        </Switch>
      </BrowserRouter>
    </IntlWrapper>
  );
}

export default Root;

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root'),
);
