import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Link } from 'react-router-dom';
import './styles.css';
SwiperCore.use([Navigation, Autoplay]);

const comments = [
  {
    id: 1,
    name: 'Carlos Almeida',
    position: 'Produtor de Café',
    image: '/images/new/pro-1.png',
    comment:
      'A Cooperativa Café do Campo gostaria de expressar sua gratidão a eLab Communications e ao Sr. Will Elkadi por todas as sugestões que fortaleceram nossas operações.',
  },
  {
    id: 2,
    name: 'Fernando Ribeiro',
    position: 'Técnico Agrícola',
    image: '/images/new/pro-2.png',
    comment:
      'Os resultados obtidos através da parceria com eLab Communications foram excepcionais para nossa plantação e gestão.',
  },
  {
    id: 3,
    name: 'Luiz Mendes',
    position: 'Produtor de Café',
    image: '/images/new/pro-3.png',
    comment:
      'Em nome dos agricultores locais, agradecemos a eLab Communications pela melhoria significativa em nossos processos.',
  },
  {
    id: 4,
    name: 'Juliana Costa',
    position: 'Técnica Agrícola',
    image: '/images/new/pro-4.png',
    comment:
      'A consultoria eLab Communications trouxe soluções inovadoras para o cultivo sustentável do café.',
  },
  {
    id: 5,
    name: 'Roberto Silva',
    position: 'Produtor de Café',
    image: '/images/new/pro-6.png',
    comment:
      'Estamos gratos pelos insights fornecidos pela eLab Communications, que impactaram positivamente nossos negócios.',
  },
  {
    id: 6,
    name: 'Mariana Oliveira',
    position: 'Técnica Agrícola',
    image: '/images/new/pro-5.png',
    comment:
      'A assistência recebida de eLab Communications foi fundamental para otimizar nossos processos no campo.',
  },
];

function TestimonialHome1() {
  const testimonialSlider = {
    slidesPerView: 1,
    speed: 500,
    spaceBetween: 24,
    loop: true,
    roundLengths: true,
    navigation: {
      nextEl: '.testi-prev1',
      prevEl: '.testi-next1',
    },

    breakpoints: {
      280: {
        slidesPerView: 1, // Mostrar "um card e meio"
        autoplay: true,
      },
      480: {
        slidesPerView: 1, // Mostrar "um card e meio"
        autoplay: true,
      },
      768: {
        slidesPerView: 1, // Mostrar "um card e meio"
        autoplay: true,
      },
      992: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 3,
      },
    },
  };

  return (
    <div className="content-testimonial">
      <div className="container">
        <div className="row justify-content-center position-relative">
          <Swiper {...testimonialSlider} className="swiper testimonial-slider">
            <div className="swiper-wrapper">
              {comments.map((item) => (
                <SwiperSlide key={item?.id} className="swiper-slide">
                  <div
                    className="testimonial-single hover-border1 wow fadeInDown"
                    data-wow-duration="1.5s"
                    data-wow-delay=".2s"
                  >
                    <img
                      alt="testimonial-images"
                      src={
                        process.env.PUBLIC_URL + '/images/icons/quote-green.svg'
                      }
                      className="quote-icon"
                    />
                    <div className="testi-img">
                      <img
                        alt="testimonial-images"
                        src={process.env.PUBLIC_URL + item?.image}
                      />
                    </div>
                    <div className="testi-content">
                      <p className="para">{item?.comment}</p>
                      <div className="testi-designation">
                        <h5>
                          <Link to={'#'}>{item?.name}</Link>
                        </h5>
                        <p>{item?.position}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
          <div className="slider-arrows testimonial2-arrow d-flex justify-content-between gap-3">
            <div
              className="testi-prev1 swiper-prev-arrow"
              tabIndex={0}
              role="button"
              aria-label="Previous slide"
            >
              <i className="bi bi-arrow-left" />
            </div>
            <div
              className="testi-next1 swiper-next-arrow"
              tabIndex={0}
              role="button"
              aria-label="Next slide"
            >
              <i className="bi bi-arrow-right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestimonialHome1;
