import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from 'react';
import { batchsApi, producersApi } from 'api';
import { useParams } from 'react-router-dom';
import { AuctionDetailsTab } from './components/tabs';
import { AuctionDetailsHeader } from './components/header';
import { useAuth } from 'hooks/use-auth';

export const AuctionContext = createContext({});

function useAuctionContext() {
  return useContext(AuctionContext);
}

const AuctionDetails = () => {
  const { token } = useAuth();
  const params = useParams();
  // const history = useHistory();

  const [batch, setBatch] = useState(null);
  const [producer, setProducer] = useState(null);
  const [loading, setLoading] = useState(false);

  const getData = useCallback(async (batchId) => {
    try {
      setLoading(true);
      const batch = await batchsApi.get(batchId);
      const producerId = batch?.producerId;
      const producer = await producersApi.get(producerId);

      setBatch(batch);
      setProducer(producer);
    } catch (err) {
      // history.push('/error');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const { id } = params;
    getData(id);
  }, [getData, params]);

  return (
    <AuctionContext.Provider
      value={{ batch, producer, loading, userToken: token }}
    >
      <div className="auction-details-section py-5 bg-light">
        <div className="container">
          <AuctionDetailsHeader />
          <AuctionDetailsTab />
        </div>
      </div>
    </AuctionContext.Provider>
  );
};

export { AuctionDetails, useAuctionContext };
