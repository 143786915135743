import React from 'react';
import { intl } from 'lib/intl';
import { LiveAuctionCard } from 'components';

const data = [
  {
    id: 'csadfsd',
    meters: 1200,
    producer: 'João Paulo',
    typeOfCoffee: 'Arábica',
    classification: '75,90',
    title: 'Fazenda café bom',
    image: `${process.env.PUBLIC_URL + '/images/new/cafe-01.png'}`,
  },
  {
    id: 'trefgsa',
    meters: 1300,
    producer: 'Maria Silva',
    typeOfCoffee: 'Robusta',
    classification: '82,30',
    title: 'Sítio Serra Azul',
    image: `${process.env.PUBLIC_URL + '/images/new/cafe-02.png'}`,
  },
  {
    id: 'kwmndkd',
    meters: 1100,
    producer: 'Carlos Oliveira',
    typeOfCoffee: 'Arábica',
    classification: '78,50',
    title: 'Fazenda Vista Alegre',
    image: `${process.env.PUBLIC_URL + '/images/new/cafe-03.png'}`,
  },
  {
    id: 'slkdfoi',
    meters: 1400,
    producer: 'Ana Souza',
    typeOfCoffee: 'Arábica',
    classification: '84,10',
    title: 'Fazenda Esperança',
    image: `${process.env.PUBLIC_URL + '/images/new/cafe-04.png'}`,
  },
  {
    id: 'vbnrewo',
    meters: 900,
    producer: 'Lucas Mendes',
    typeOfCoffee: 'Robusta',
    classification: '73,20',
    title: 'Chácara do Norte',
    image: `${process.env.PUBLIC_URL + '/images/new/cafe-02.png'}`,
  },
  {
    id: 'qoiujwr',
    meters: 1200,
    producer: 'Paula Andrade',
    typeOfCoffee: 'Arábica',
    classification: '85,00',
    title: 'Sítio Bela Vista',
    image: `${process.env.PUBLIC_URL + '/images/new/cafe-04.png'}`,
  },
  {
    id: 'alnhurw',
    meters: 1350,
    producer: 'Fernando Lima',
    typeOfCoffee: 'Arábica',
    classification: '80,75',
    title: 'Fazenda Santa Clara',
    image: `${process.env.PUBLIC_URL + '/images/new/cafe-01.png'}`,
  },
  {
    id: 'nuiresd',
    meters: 1000,
    producer: 'Ricardo Pinto',
    typeOfCoffee: 'Robusta',
    classification: '77,40',
    title: 'Sítio Recanto Verde',
    image: `${process.env.PUBLIC_URL + '/images/new/cafe-03.png'}`,
  },
  {
    id: 'pivlcxz',
    meters: 1250,
    producer: 'Juliana Martins',
    typeOfCoffee: 'Arábica',
    classification: '83,20',
    title: 'Fazenda Paraíso',
    image: `${process.env.PUBLIC_URL + '/images/new/cafe-02.png'}`,
  },
  {
    id: 'bntrefw',
    meters: 950,
    producer: 'Eduardo Rocha',
    typeOfCoffee: 'Robusta',
    classification: '74,60',
    title: 'Chácara São João',
    image: `${process.env.PUBLIC_URL + '/images/new/cafe-04.png'}`,
  },
  {
    id: 'okbdcvn',
    meters: 1100,
    producer: 'Patrícia Faria',
    typeOfCoffee: 'Arábica',
    classification: '81,50',
    title: 'Sítio Alto da Serra',
    image: `${process.env.PUBLIC_URL + '/images/new/cafe-03.png'}`,
  },
  {
    id: 'ftgdsza',
    meters: 1450,
    producer: 'Rafael Costa',
    typeOfCoffee: 'Arábica',
    classification: '86,30',
    title: 'Fazenda Nova Esperança',
    image: `${process.env.PUBLIC_URL + '/images/new/cafe-01.png'}`,
  },
];

const LiveAuctionWrap = () => {
  return (
    <div className="py-5">
      <div className="container">
        <div className="d-flex flex-column gap-4">
          <h2 className="text-secondary fs-1 fw-semibold">{intl('offers')}</h2>
          <div className="card-grid-a">
            {data.map((item) => (
              <LiveAuctionCard
                key={item.id}
                meters={item.meters}
                producer={item.producer}
                typeOfCoffee={item.typeOfCoffee}
                classification={item.classification}
                title={item.title}
                image={item.image}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export { LiveAuctionWrap };
