import { useState, useEffect } from 'react';
import { getSession } from 'utils/handleSession';

export function useAuth() {
  const [session, setSession] = useState(null);

  useEffect(() => {
    const session = getSession();
    if (!session) {
      return;
    }

    setSession(session);
  }, []);

  return { ...session, setSession };
}
