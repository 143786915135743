import { useState, useEffect, useCallback } from 'react';
import './styles.css';
import { intl } from 'lib/intl';
import Slider from '@mui/material/Slider';
import { batchsApi, producersApi } from 'api';
import { LiveAuctionCard } from './components/live-auction-card';
import { Pagination, Loading, CardProducer, FormElements } from 'components';

const PAGE_SIZE = 20;

const FILTERS = {
  page: 1,
  pageSize: PAGE_SIZE,
};

const minDistance = 0;

const EventPage = () => {
  const [producers, setProducers] = useState(null);
  const [batches, setBatches] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState(FILTERS);
  const [score, setScore] = useState([75, 100]);
  const [search, setSearch] = useState('');

  const getProducers = useCallback(async () => {
    try {
      const params = {
        page: 1,
        pageSize: 20,
        finalist: 1,
      };
      const [producers] = await Promise.all([producersApi.list(params)]);
      setProducers(producers.content);
    } catch (err) {}
  }, []);

  const getBatchs = useCallback(async (filters) => {
    try {
      setLoading(true);
      const params = {
        ...filters,
        eventId: '1c1f85ee-cc4c-42f9-b8fa-22c13d56a882',
      };
      const [batchs] = await Promise.all([batchsApi.list(params)]);
      setBatches(batchs);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getBatchs({ ...filters, search, minScore: score[0], maxScore: score[1] });
  }, [getBatchs, filters, search, score]);

  useEffect(() => {
    getProducers();
  }, [getProducers]);

  const handlePage = (_, page) => {
    setFilters((old) => ({ ...old, page, pageSize: PAGE_SIZE }));
  };

  const changePerPage = (e) => {
    setFilters((old) => ({ ...old, pageSize: e.target?.value || PAGE_SIZE }));
  };

  const handleChangeScore = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setScore([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setScore([clamped - minDistance, clamped]);
      }
    } else {
      setScore(newValue);
    }
  };

  return (
    <div>
      <div
        className="section-background"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + '/images/new/bg-cafe.jpg'
          })`,
        }}
      >
        <div className="container section-container">
          <div className="section-content">
            <div className="logo-container">
              <img
                src={process.env.PUBLIC_URL + '/images/new/logo-sic.png'}
                alt="Logo SIC"
                className="logo-image"
              />
            </div>
            <div className="text-box">
              <p className="text-sic-a">{intl('sicEventApresentation')}</p>
              <p className="text-sic">{intl('sicEventOffer')}</p>
            </div>
          </div>
        </div>
      </div>
      {producers?.length > 0 && (
        <div className="finalists-section">
          <div className="container">
            <h2 className="finalists-title mb-4">{intl('finalBidders')}</h2>
            <div className="card-grid">
              {producers?.map((item) => (
                <div className="col" key={item.id}>
                  <CardProducer
                    producerId={item?.id}
                    length={producers?.length}
                    meters={item.altitudeMax}
                    producer={item.name}
                    variety={item.variety}
                    farm={item.propertyName}
                    image={item?.photo?.imageUrl}
                    city={item.city}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="lots-section">
        <div className="container">
          <h2 className="lots-title mb-4">{intl('lotsOnOffer')}</h2>
          <div className="filters-box">
            <FormElements.Search
              label="Buscar"
              value={search}
              onChange={setSearch}
              placeholder="Buscar por nome do produtor, cidade, uf e altitude"
            />
            <div className="filters-slider">
              <div className="filters-row ">
                <span className="filters-labels">Mímino</span>
                <span className="filters-labels">Maxímo</span>
              </div>
              <Slider
                min={75}
                max={100}
                sx={{ color: '#356b53' }}
                getAriaLabel={() => 'Minimum distance shift'}
                value={score}
                onChange={handleChangeScore}
                valueLabelDisplay="auto"
                disableSwap
              />
            </div>
          </div>
          {batches?.content?.length > 0 && (
            <div className="card-grid-a">
              {!loading &&
                batches?.content?.map((batch) => (
                  <LiveAuctionCard
                    key={batch?.id}
                    id={batch?.id}
                    name={batch?.name}
                    photos={batch?.photos}
                    preparation={batch?.preparation}
                    variety={batch?.variety}
                    species={batch?.species}
                    score={batch?.score}
                    producer={batch?.producer}
                    favoriteId={batch?.favoriteId}
                  />
                ))}
            </div>
          )}
          {loading && <Loading />}
          <div className="mt-4">
            <Pagination
              page={filters.page}
              rowsPerPage={filters.pageSize}
              onChange={handlePage}
              changePerPage={changePerPage}
              total={batches?.pagination?.total}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { EventPage };
