import { Link } from 'react-router-dom';
import './styles.css';

const CardProducer = ({
  farm,
  image,
  city,
  meters,
  producer,
  typeOfCoffee,
  producerId,
}) => {
  const getInitials = (name) => {
    if (!name) return '';
    const words = name.split(' ');
    const initials = words
      .map((word) => word[0])
      .slice(0, 2)
      .join('');
    return initials.toUpperCase();
  };

  return (
    <div className="shadow-standard card-producer">
      <div className="producer-details">
        <div className="bidder-img">
          {image ? (
            <img alt="images" className="producer-image" src={image} />
          ) : (
            <div className="producer-avatar">{getInitials(producer)}</div>
          )}
        </div>
        <div className="text-producer">
          <h4 className="producer-name">{producer}</h4>
          <span className="farm-name">{farm}</span>
        </div>
      </div>
      <div className="location-info">
        <img
          className="img-fluid info-icon"
          alt="localIcon"
          src={process.env.PUBLIC_URL + '/images/icons/local.svg'}
        />
        <span className="text-dark fw-semibold">{city}</span>
      </div>
      <div className="info-icons">
        <div className="info-icon-item">
          <img
            className="img-fluid info-icon"
            alt="coffeeTypeIcon"
            src={process.env.PUBLIC_URL + '/images/icons/seed.svg'}
          />
          <span className="text-dark fw-semibold">
            Quantidade: <b>120 sacas</b>
          </span>
        </div>
        <div className="info-icon-item">
          <img
            className="img-fluid info-icon"
            alt="altitudeIcon"
            src={process.env.PUBLIC_URL + '/images/icons/map.svg'}
          />
          <span className="text-dark fw-semibold">{meters} Metros</span>
        </div>
      </div>
      <Link
        to={`${process.env.PUBLIC_URL}/producer-details/${producerId}`}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        className="btn btn-success w-100 fw-semibold"
        style={{ backgroundColor: '#356B53' }}
      >
        Ver informações
      </Link>
    </div>
  );
};

export { CardProducer };
