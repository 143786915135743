import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { intl } from 'lib/intl';

const bgImage = process.env.PUBLIC_URL + '/images/new/cafe-01.png';

const LiveAuctionCard = ({ id, title, image, producer, variety, score }) => {
  const [hasFavorite, setHasFavorite] = useState(false);

  const handleFavoriteClick = () => {
    setHasFavorite(!hasFavorite);
  };

  const linkTo = `${process.env.PUBLIC_URL}/auction-details/${id}/${producer.id}`;

  const img = image ? image[0]?.imageUrl : '';

  return (
    <div className="card w-100 shadow-sm rounded overflow-hidden">
      <div
        className="card-img-top position-relative"
        style={{
          height: '16rem',
          backgroundImage: `url(${img || bgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <button
          onClick={handleFavoriteClick}
          className="btn position-absolute m-2 end-0"
        >
          {hasFavorite ? (
            <img
              className="img-fluid"
              style={{ width: '28px', height: '28px' }}
              alt="liveAuctionCardImage"
              src={process.env.PUBLIC_URL + '/images/icons/heart-on.svg'}
            />
          ) : (
            <img
              className="img-fluid"
              style={{ width: '28px', height: '28px' }}
              alt="liveAuctionCardImage"
              src={process.env.PUBLIC_URL + '/images/icons/heart-off.svg'}
            />
          )}
        </button>
      </div>
      <div className="card-body d-flex flex-column gap-3 bg-white">
        <div className="d-flex gap-2">
          <div className="bg-light px-3 py-1 rounded d-flex align-items-center gap-1">
            <img
              className="img-fluid"
              style={{ width: '20px', height: '20px' }}
              alt="liveAuctionCardImage"
              src={process.env.PUBLIC_URL + '/images/icons/medalha.svg'}
            />
            <span className="text-success fw-semibold">{score}</span>
          </div>
          <div className="bg-light px-3 py-1 rounded">
            <span className="text-success fw-semibold">
              {intl('fermented')}
            </span>
          </div>
        </div>
        <div className="producer-info">
          <span className="producer-title">{title}</span>
          <span className="producer-subtitle">{producer.name}</span>
        </div>
        <div className="d-flex gap-2">
          <div className="d-flex align-items-center gap-1">
            <img
              className="img-fluid"
              style={{ width: '20px', height: '20px' }}
              alt="liveAuctionCardImage"
              src={process.env.PUBLIC_URL + '/images/icons/seed.svg'}
            />
            <span className="text-dark fw-semibold">{variety}</span>
          </div>
          <div className="d-flex align-items-center gap-1">
            <img
              className="img-fluid"
              style={{ width: '20px', height: '20px' }}
              alt="liveAuctionCardImage"
              src={process.env.PUBLIC_URL + '/images/icons/map.svg'}
            />
            <span className="text-dark fw-semibold">
              {producer.altitudeMax} {intl('meters')}
            </span>
          </div>
        </div>
        <Link
          to={linkTo}
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          className="btn btn-success w-100 fw-semibold"
        >
          {intl('seeInformation')}
        </Link>
      </div>
    </div>
  );
};

export { LiveAuctionCard };
