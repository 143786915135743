import React, { useCallback, useEffect, useState } from 'react';
import { LiveAuctionCard } from 'components/live-auction-card';
import './style.css';
import { batchsApi } from 'api';

const data = [
  {
    id: 'csadfsd',
    meters: 1200,
    producer: 'João Paulo',
    typeOfCoffee: 'Arábica',
    classification: '75,90',
    title: 'Fazenda café bom',
    image: `${process.env.PUBLIC_URL + '/images/new/cafe-01.png'}`,
  },
  {
    id: 'trefgsa',
    meters: 1300,
    producer: 'Maria Silva',
    typeOfCoffee: 'Robusta',
    classification: '82,30',
    title: 'Sítio Serra Azul',
    image: `${process.env.PUBLIC_URL + '/images/new/cafe-02.png'}`,
  },
  {
    id: 'kwmndkd',
    meters: 1100,
    producer: 'Carlos Oliveira',
    typeOfCoffee: 'Arábica',
    classification: '78,50',
    title: 'Fazenda Vista Alegre',
    image: `${process.env.PUBLIC_URL + '/images/new/cafe-03.png'}`,
  },
  {
    id: 'slkdfoi',
    meters: 1400,
    producer: 'Ana Souza',
    typeOfCoffee: 'Arábica',
    classification: '84,10',
    title: 'Fazenda Esperança',
    image: `${process.env.PUBLIC_URL + '/images/new/cafe-04.png'}`,
  },
];

const LIST_RANDOM_BATCHES = {
  content: [],
};

export function OtherAuctions() {
  const [listRandomBatches, setListRandomBatches] =
    useState(LIST_RANDOM_BATCHES);
  const [loading, setLoading] = useState(false);

  const getData = useCallback(async (filters) => {
    try {
      setLoading(true);
      const response = await batchsApi.randomList(filters);
      console.info(response);
      setListRandomBatches(response);
    } catch (err) {
      console.error('Error fetching buyer data:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => getData(), [getData]);

  return (
    <div
      style={{
        marginTop: 20,
      }}
      className="tab-pane fade mobile-display"
      id="pills-contact"
      role="tabpanel"
      aria-labelledby="pills-contact-tab"
    >
      <div className="card-grid-a">
        {listRandomBatches?.content?.length > 0 &&
          listRandomBatches?.content?.map((item) => (
            <LiveAuctionCard
              key={item.id}
              meters={item.meters}
              producer={item.producer}
              typeOfCoffee={item.typeOfCoffee}
              classification={item.classification}
              title={item.title}
              image={item.photos}
            />
          ))}
      </div>
    </div>
  );
}
