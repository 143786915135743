import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import { intl } from 'lib/intl';

function Footer(props) {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });
  return (
    <footer>
      <div className="footer-top">
        <div className="container">
          <div className="footer-content">
            <div className="footer-item">
              <Link
                to={`${process.env.PUBLIC_URL}/`}
                onClick={scrollTop}
                className="footer-logo"
              >
                <img
                  alt="images"
                  src={`${process.env.PUBLIC_URL}/images/bg/logo-selecta-cafe.png`}
                />
              </Link>
            </div>
            <div className="footer-row">
              <div className="footer-item">
                <h5>Parceiros</h5>
                <a
                  className="footer-logo"
                  target="_blank"
                  href="https://www.faemg.org.br/"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="images"
                    width="50%"
                    height="auto"
                    src={`${process.env.PUBLIC_URL}/images/new/logo_faemg.webp`}
                  />
                </a>
              </div>
              <div className="footer-item">
                <h5>Navegação</h5>
                <ul className="footer-list">
                  <li>
                    <Link onClick={scrollTop} to={'/'}>
                      {intl('home')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollTop}
                      to={`${process.env.PUBLIC_URL}/event-page`}
                    >
                      {intl('events')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollTop}
                      to={`${process.env.PUBLIC_URL}/my-account`}
                    >
                      {intl('myAccount')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollTop}
                      to={`${process.env.PUBLIC_URL}/my-account`}
                    >
                      {intl('myCoffees')}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row d-flex align-items-center g-4">
            <div className="col-lg-6 d-flex justify-content-lg-start justify-content-center">
              <p>
                Copyright 2024 Selecta Cafés | Tecnologia{' '}
                <a
                  className="link-agroger"
                  href="https://agroger.com.br"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Agroger
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
