import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import { intl } from 'lib/intl';
import { useAuth } from 'hooks/use-auth';
import { useHistory } from 'react-router-dom';
import { favoritesApi } from 'api';

const LiveAuctionCard = ({
  title,
  image,
  photos,
  meters,
  producer,
  typeOfCoffee,
  classification,
  preparation,
  batchId,
  favoriteId,
}) => {
  const { token } = useAuth();
  const history = useHistory();

  const [hasFavorite, setHasFavorite] = useState(false);

  const handleFavoriteClick = () => {
    if (!token) {
      history.push(`${process.env.PUBLIC_URL}/login`);
    } else {
      setHasFavorite(!hasFavorite);
      createFavorite();
    }
  };

  const createFavorite = useCallback(async () => {
    try {
      await favoritesApi.create({ batchId });
    } catch (err) {
    } finally {
    }
  }, [batchId]);

  useEffect(() => {
    if (favoriteId) {
      setHasFavorite(true);
    }
  }, [favoriteId]);

  const bgImage = process.env.PUBLIC_URL + '/images/bg/empty-image.png';
  const img = photos?.length > 0 ? photos[0]?.imageUrl : bgImage;

  return (
    <div className="live-auction-card">
      <div
        className="live-auction-card-image position-relative"
        style={{ backgroundImage: `url(${img || bgImage})` }}
      >
        <button onClick={handleFavoriteClick} className="favorite-button btn">
          {hasFavorite ? (
            <img
              className="img-fluid favorite-icon"
              alt="favorite"
              src={process.env.PUBLIC_URL + '/images/icons/heart-on.svg'}
            />
          ) : (
            <img
              className="img-fluid favorite-icon"
              alt="not-favorite"
              src={process.env.PUBLIC_URL + '/images/icons/heart-off.svg'}
            />
          )}
        </button>
      </div>
      <div className="live-auction-card-body">
        <div className="classification-info">
          <div className="classification-badge">
            <img
              className="img-fluid classification-icon"
              alt="classificationIcon"
              src={process.env.PUBLIC_URL + '/images/icons/medalha.svg'}
            />
            <span className="classification-text">{classification}</span>
          </div>
          <div className="classification-badge">
            <span className="classification-text">{preparation}</span>
          </div>
        </div>
        <div className="producer-info">
          <span className="producer-title">{title}</span>
          <span className="producer-subtitle">{producer}</span>
        </div>
        <div className="info-icons">
          <div className="info-icon-item">
            <img
              className="img-fluid info-icon"
              alt="coffeeIcon"
              src={process.env.PUBLIC_URL + '/images/icons/seed.svg'}
            />
            <span className="text-dark fw-semibold">{typeOfCoffee}</span>
          </div>
          <div className="info-icon-item">
            <img
              className="img-fluid info-icon"
              alt="mapIcon"
              src={process.env.PUBLIC_URL + '/images/icons/map.svg'}
            />
            <span className="text-dark fw-semibold">
              {meters} {intl('meters')}
            </span>
          </div>
        </div>
        <Link
          to={`${process.env.PUBLIC_URL}/auction-details/${batchId}`}
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          className="btn btn-success w-100 fw-semibold"
          style={{ backgroundColor: '#356B53' }}
        >
          Ver informações
        </Link>
      </div>
    </div>
  );
};

export { LiveAuctionCard };
