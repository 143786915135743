import React from 'react';
import AboutUsCounter from 'components/common/AboutUsCounter';
// import Breadcrumb from 'components/common/Breadcrumb';
import DashbordWrap from './components/DashboardWrap';

const MyAccount = () => {
  return (
    <>
      {/* <Breadcrumb pageName="MyAccount" pageTitle="MyAccount" /> */}
      <DashbordWrap />
      <AboutUsCounter />
    </>
  );
};

export { MyAccount };
