import { useState } from 'react';
import { FormInput } from '../form-input';
import { intl } from 'lib/intl';

export function PasswordInput({ name, label, handlePassword, error }) {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword((show) => !show);

  return (
    <div className="position-relative d-flex">
      <FormInput
        name={name}
        type={showPassword ? 'text' : 'password'}
        label={label}
        error={error}
        onChange={handlePassword}
        required
      />
      <i
        className={`position-absolute bottom-0 translate-middle ${
          showPassword ? 'bi bi-eye-slash' : 'bi bi-eye-slash bi-eye'
        }`}
        style={{ right: '0.5rem', cursor: 'pointer' }}
        onClick={toggleShowPassword}
        id="togglePassword"
      />
    </div>
  );
}
