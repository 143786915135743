import axios from 'axios';
import { batchsApi } from './batchs';
import { producersApi } from './producers';
import { bidsApi } from './bids';
import { buyersApi } from './buyers';
import { authApi } from './auth';
import { usersApi } from './users';
import { dashboardApi } from './dashboard';
import { favoritesApi } from './favorites';

export const apiBase = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/v1/`,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiBase.interceptors.request.use(
  (config) => {
    const sessionData = localStorage.getItem('@agroger:session');

    if (sessionData) {
      const parsedSession = JSON.parse(sessionData);
      const token = parsedSession.token;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export {
  batchsApi,
  producersApi,
  bidsApi,
  buyersApi,
  authApi,
  usersApi,
  dashboardApi,
  favoritesApi,
};
