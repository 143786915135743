import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { intl } from 'lib/intl';
import { FormInput } from 'components/form-elements/form-input';
import { PasswordInput } from 'components/form-elements/form-password-input';
import { Notification } from 'components/common/Notification';

import { validarEmail } from 'utils/validarEmail';
import { authApi } from 'api';
import { getLastBatch, saveSession, getSession } from 'utils/handleSession';
import { useHistory } from 'react-router-dom';

function LoginWrap() {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [notificationMessage, setNotificationMessage] = useState({
    message: '',
    type: '',
  });
  function resetNotificationMessage() {
    setNotificationMessage({
      message: '',
      type: '',
    });
  }

  function handleEmailValidation(value) {
    const isValid = validarEmail(value);
    if (isValid) {
      setErrors((errors) => {
        return { ...errors, email: null };
      });
      return;
    }

    setErrors((errors) => {
      return { ...errors, email: 'Email inválido' };
    });
  }

  async function handleLoginSubmit(formEvent) {
    formEvent.preventDefault();
    try {
      setLoading(true);
      const form = new FormData(formEvent.target);
      const [email, password] = [form.get('email'), form.get('password')];
      const session = await authApi.createSession({
        email,
        password,
      });
      saveSession(session);
      const lastBatchLink = getLastBatch();
      if (lastBatchLink) {
        history.replace(lastBatchLink);
      } else {
        history.push(`${process.env.PUBLIC_URL}/`);
      }

      formEvent.target.reset();
    } catch (err) {
      setNotificationMessage({
        message: err.response.data.error,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const session = getSession();
    if (session) {
      history.push(`${process.env.PUBLIC_URL}/`);
    }
  }, []);

  return (
    <>
      <Notification
        message={intl(notificationMessage?.message)}
        isOpen={!!notificationMessage?.message}
        type={notificationMessage?.type}
        onClose={resetNotificationMessage}
      />
      <div className="login-section pt-120 pb-120">
        <img
          alt="imges"
          src="assets/images/bg/section-bg.png"
          className="img-fluid section-bg-top"
        />
        <img
          alt="imges"
          src="assets/images/bg/section-bg.png"
          className="img-fluid section-bg-bottom"
        />
        <div className="container">
          <div className="row d-flex justify-content-center g-4">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <div
                className="form-wrapper wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
              >
                <div className="form-title">
                  <h3>{intl('logInAction')}</h3>
                  <p>
                    {intl('newMember')}{' '}
                    <Link
                      to={`${process.env.PUBLIC_URL}/buyer-registration`}
                      onClick={() =>
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                      }
                    >
                      {intl('signUp')}
                    </Link>
                  </p>
                </div>
                <form className="w-100" onSubmit={handleLoginSubmit}>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-inner">
                        <FormInput
                          name="email"
                          label={intl('email')}
                          placeholder="ex: joaoenzo@gmail.com"
                          error={errors?.email}
                          required
                          onChange={handleEmailValidation}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-inner">
                        <PasswordInput
                          name="password"
                          label={intl('password')}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-agreement form-inner d-flex justify-content-between flex-wrap">
                        <div className="form-group">
                          <input type="checkbox" id="html" />
                          <label htmlFor="html">
                            {intl('agreeTerms')}{' '}
                            <Link to={'#'}>
                              {intl('terms')} &amp; {intl('policy')}
                            </Link>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button className="account-btn">{intl('logInAction')}</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginWrap;
